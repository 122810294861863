// 验证手机号码
export function mobileCheck(phone) {
  const myReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  return myReg.test(phone);
}

// 验证身份证
export function sfzNumCheck(sfzNum) {
  const myReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  return myReg.test(sfzNum);
}

// 验证邮箱
export function mailCheck(mail) {
  const myReg = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/;
  return myReg.test(mail);
}

export function getDate(fmt, dt = new Date()) {
  let o = {
    "M+": dt.getMonth() + 1, //月份
    "d+": dt.getDate(), //日
    "h+": dt.getHours(), //小时
    "m+": dt.getMinutes(), //分
    "s+": dt.getSeconds(), //秒
    "q+": Math.floor((dt.getMonth() + 3) / 3), //季度
    S: dt.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (dt.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (let k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
}

// 获取两个日期相差的好描述
export function timeSpan(d1, d2) {
  let dateBegin = new Date(d1.replace(/-/g, "/")); //将-转化为/，使用new Date
  let dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date

  let dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数

  return Math.round(dateDiff / 1000);
}

// 图片转为base64格式
export function imageToBase64(img) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
  var dataURL = canvas.toDataURL("image/jpeg/png" + ext);
  return dataURL;
};


export function base64ToFile(urlData, fileName) {
  let arr = urlData.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bytes = atob(arr[1]); // 解码base64
  let n = bytes.length
  let ia = new Uint8Array(n);
  while (n--) {
    ia[n] = bytes.charCodeAt(n);
  }
  return new File([ia], fileName, {
    type: mime
  });
}

// 补0
export function add0(m) {
  return m < 10 ? "0" + m : m;
}
// 时间转化
export function formatTime(data) {
  var time = new Date(data);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var ss = time.getSeconds();
  return (y + "-" + add0(m) + "-" + add0(d) + " " + add0(h) + ":" + add0(mm) + ":" + add0(ss));
}


